import { CheckPromotionType, PageReqOptionDto } from '@/types';

import {
  DeviceByIdResDto,
  DeviceDetailListResDto,
  DeviceListResDto,
  DevicePutReqDto,
  instance,
} from '@/apis';

import { API } from '@/constants';

type ID = string;

/**
 * 디바이스 리스트 보기(paginated) 디바이스 페이지의 테이블에 사용됩니다
 *
 * @param pageOption -
 *
 *   - "page": "number", -> 현재 페이지
 *   - "take": "number", -> 페이지네이션 범위
 *   - "q": "string" -> 검색할 이름의 일부분
 *
 * @returns 200: 페이지, 검색어에 따른 디바이스 리스트를 반환합니다(DeviceListResDto)
 */
export const getDevices = async (pageOption?: PageReqOptionDto) => {
  const { data } = await instance.get<DeviceListResDto>(
    pageOption ? API.DEVICE_PAGE(pageOption) : API.DEVICE,
  );
  return data;
};

/**
 * 디바이스 상세 정보를 포함한 리스트 보기(paginated) 재생목록에서 디바이스에 재생목록을 등록할 때 모달에서
 * 사용됩니다(PlaylistDeviceRegistrationModal)
 *
 * @param pageOption -
 *
 *   - "page": "number", -> 현재 페이지
 *   - "take": "number", -> 페이지네이션 범위
 *   - "q": "string" -> 검색할 이름의 일부분
 *
 * @returns 페이지, 검색어에 따른 디바이스 리스트를 반환합니다(DeviceDetailListResDto)
 */
export const getDevicesDetail = async (pageOption?: PageReqOptionDto) => {
  const { data } = await instance.get<DeviceDetailListResDto>(
    pageOption ? API.DEVICE_DETAIL(pageOption) : API.DEVICE,
  );
  return data;
};

/**
 * 단일 디바이스 정보 보기
 *
 * @param id 디바이스 아이디
 * @returns 특정 디바이스의 상세 정보를 반환합니다(DeviceByIdResDto)
 */
export const getDeviceById = async (id: ID) => {
  const { data } = await instance.get<DeviceByIdResDto>(API.DEVICE_ID(id));
  return data;
};

/**
 * 단일 디바이스 정보 수정
 *
 * @param id 디바이스 아이디
 * @param body -
 *
 *   - "name": "string", -> 기기 이름
 *   - "model": "string", -> 기기 모델
 *   - "popup": { id : "string"} | null -> 팝업레이어 정보
 *   - "devicePlayLists": DevicePlayListReqDto -> 플레이리스트 정보
 */
export const putDeviceById = async (id: ID, body: DevicePutReqDto) => {
  return await instance.put(API.DEVICE_ID(id), body);
};

/**
 * 디바이스 삭제
 *
 * @param id 디바이스 아이디, 여러 개일 경우 1, 2, 3...과 같은 형태로 전송
 * @param body DevicePutReqDto
 */
export const deleteDeviceById = async (id: ID) => {
  return await instance.delete(API.DEVICE_ID(id));
};

/**
 * 디바이스에 팝업 등록
 *
 * @param id 등록할 디바이스 id 리스트 (','으로 구별)
 * @param body DevicePutReqDto
 */
export const postAddPopupToDeviceByIds = async (
  ids: Array<ID>,
  popupId: ID,
) => {
  return await instance.post(API.DEVICE_IDS_POPUP_ID(ids, popupId));
};

/**
 * 디바이스에 재생목록 등록
 *
 * @param id 등록할 디바이스 id 리스트 (','으로 구별)
 * @param playlistid등록할 재생목록 id
 */
export const postAddPlayListToDeviceByIds = async (
  ids: Array<ID>,
  playListId: ID,
) => {
  return await instance.post(API.DEVICE_IDS_PLAYLIST_ID(ids, playListId));
};

/**
 * 디바이스 이름 중복. 체크
 *
 * @param id 디바이스 아이디
 * @param name 체크할 이름
 * @returns 이름이 중복인지 boolean 값으로 반환합니다 ({"isNameDuplicated": true})
 */
export const checkDuplicatedDeviceName = async (id: ID, name: string) => {
  const { data } = await instance.get<{ isNameDuplicated: boolean }>(
    API.DEVICE_CHECK_NAME,
    { params: { id, name } },
  );
  return data.isNameDuplicated;
};

/**
 * 디바이스 프로모션 중복. 체크
 *
 * @param id 디바이스 아이디
 * @param name 체크할 이름
 * @returns 이름이 중복인지 boolean 값으로 반환합니다 ({"isNameDuplicated": true})
 */
export const checkDevicePromotions = async (
  promotions: CheckPromotionType[],
) => {
  const res = await instance.post(API.DEVICE_CHECK_PROMOTIONS, promotions);
  return res;
};

/**
 * 앱 새로고침
 *
 * @param id 디바이스 아이디
 */
export const appReload = async (id: ID) => {
  return await instance.post(API.DEVICE_ID_RELOAD(id));
};
