import {
  ApiErrorResponse,
  CheckPromotionType,
  ModifyPromotionMappedToDeviceResDto,
  NewPromotionMappedToDeviceResDto,
} from '@/types';

import { DevicePopupResDto, PlayListItemResDto } from '@/apis';

import {
  AddPlaylistModal,
  AddPopupModal,
  Button,
  ButtonLoadingTypes,
  DeviceModiftyPlaylistCard,
  Empty,
  Header,
  LeaveCurrentPageModal,
  LimitGuide,
  PreviewContentsModal,
  PromotionCalendar,
  TextField,
  useModal,
} from '@/components';

import {
  useAppReloadMutation,
  useCheckDeviceNameMutation,
  useDebounce,
  useDeviceQuery,
  useModifyDeviceMutation,
} from '@/hooks';

import { isArrEmpty } from '@/utils';

import { API_ERROR_CODE, INVALID_MASSAGE, PATH } from '@/constants';

import { PALETTE } from '@/themes';

import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaPhotoVideo } from 'react-icons/fa';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { IoPlayCircleOutline, IoReloadOutline } from 'react-icons/io5';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import * as S from './index.style';

export type DevicePlayListType = {
  id?: string;
  isDefault: boolean;
  promotions: Array<ModifyPromotionMappedToDeviceResDto>;
  playList: PlayListItemResDto;
};

const NEW_PROMOTION_ID = 'UNROLL_';
const LIMIT_PLAYLIST = 5;
const LIMIT_PROMOTION = 50;

export const DeviceModifyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const parmas = useParams();
  const deviceId = useMemo(() => parmas.deviceId ?? '', [parmas]);
  const prevUrl = location.state?.prevUrl ?? PATH.DEVICE_PAGE;

  const [buttonState, setButtonState] = useState<ButtonLoadingTypes>('normal');
  const [playlists, setPlaylists] = useState<DevicePlayListType[]>([]);
  const [checkPromotions, setCheckPromotions] = useState<CheckPromotionType[]>(
    [],
  );
  const [popup, setPopup] = useState<DevicePopupResDto | null>(null);
  const [name, setName] = useState('');
  const debouncedName = useDebounce(name);
  const [model, setModel] = useState('');

  const [nameError, setNameError] = useState({ isError: false, message: '' });
  const { data } = useDeviceQuery({ deviceId });

  const isSaveDisabled = useMemo(
    () =>
      debouncedName.length === 0 ||
      nameError.isError ||
      isArrEmpty(playlists) ||
      !playlists.some((v) => v.isDefault),
    [nameError.isError, debouncedName, playlists],
  );

  const { mutate: modifyDevice } = useModifyDeviceMutation({
    deviceId,
    onMutate: () => {
      setButtonState('loading');
    },
    onSuccess: () => {
      const successTimeout = setTimeout(() => {
        setButtonState('complete');
        const timeout = setTimeout(() => {
          setButtonState('normal');
          clearTimeout(timeout);
        }, 1000);
        clearTimeout(successTimeout);
      }, 500);
    },
    onError: (error: AxiosError<ApiErrorResponse>) => {
      if (
        error.response?.data.errorCode === API_ERROR_CODE.CONFLICT_DEVICENAME
      ) {
        setNameError({
          isError: true,
          message: INVALID_MASSAGE.ALREADY_USED_NAME,
        });
      }
      setButtonState('normal');
    },
  });
  const { mutate: checkDuplicatedName } = useCheckDeviceNameMutation({
    onSuccess: (isDuplicated) => {
      if (isDuplicated) {
        setNameError({
          isError: true,
          message: INVALID_MASSAGE.ALREADY_USED_NAME,
        });
      } else {
        setNameError({ isError: false, message: '' });
      }
    },
    onError: () =>
      setNameError({
        isError: true,
        message: INVALID_MASSAGE.INVALID_DEVICE_NAME,
      }),
  });

  const { mutate: appReload } = useAppReloadMutation();

  const popupModal = useModal(() => (
    <AddPopupModal
      closeModal={popupModal.closeModal}
      popup={popup}
      setPopup={setPopup}
    />
  ));
  const playlistModal = useModal(() => (
    <AddPlaylistModal
      closeModal={playlistModal.closeModal}
      maxSelectCount={LIMIT_PLAYLIST - playlists.length}
      playlists={playlists}
      setPlaylists={setPlaylists}
    />
  ));
  const previewModal = useModal(() => (
    <PreviewContentsModal
      playContentsList={playlists.map((v) => v.playList)}
      closeModal={previewModal.closeModal}
    />
  ));
  const leavePageAlertModal = useModal(() => (
    <LeaveCurrentPageModal
      closeModal={leavePageAlertModal.closeModal}
      onSubmit={() => {
        navigate(prevUrl, { replace: true });
        leavePageAlertModal.closeModal();
      }}
    />
  ));

  const setDefaultPlaylistHandler = useCallback((playlistId: string) => {
    setPlaylists((prev) =>
      prev.map((v) => {
        const isDefault = v.playList.id === playlistId;
        if (isDefault) {
          setCheckPromotions((o) =>
            o.filter((p) => !v.promotions.some((s) => s.id === p.id)),
          );
        }

        return {
          ...v,
          promotions: isDefault ? [] : v.promotions,
          isDefault: isDefault,
        };
      }),
    );
  }, []);

  const deletePlaylistHandler = useCallback((playlistId: string) => {
    setPlaylists((prev) => prev.filter((v) => v.playList.id !== playlistId));
  }, []);

  const addPromotionHandler = useCallback(
    async (playlistId: string, promotion: NewPromotionMappedToDeviceResDto) => {
      setPlaylists((prev) =>
        prev.map((v, i) => {
          return v.playList.id === playlistId
            ? (() => {
                setCheckPromotions((p) => [
                  ...p,
                  {
                    ...promotion,
                    id: `${NEW_PROMOTION_ID}${i}_${v.promotions.length}`,
                  },
                ]);

                return {
                  ...v,
                  promotions: [
                    ...v.promotions,
                    {
                      ...promotion,
                      id: `${NEW_PROMOTION_ID}${i}_${v.promotions.length}`,
                    },
                  ],
                };
              })()
            : v;
        }),
      );
    },
    [],
  );

  const editPromotionHandler = useCallback(
    (
      playlistId: string,
      promotionId: string,
      promotion: ModifyPromotionMappedToDeviceResDto,
    ) => {
      setCheckPromotions((prev) =>
        prev.map((p) =>
          p.id === promotionId
            ? {
                ...promotion,
              }
            : p,
        ),
      );

      setPlaylists((prev) =>
        prev.map((v) =>
          v.playList.id === playlistId
            ? {
                ...v,
                promotions: v.promotions.map((p) =>
                  p.id === promotionId
                    ? {
                        ...promotion,
                      }
                    : p,
                ),
              }
            : v,
        ),
      );
    },
    [],
  );

  const deletePromotionHandler = useCallback(
    (playlistId: string, promotionId: string) => {
      setCheckPromotions((prev) => prev.filter((p) => p.id !== promotionId));
      setPlaylists((prev) =>
        prev.map((v) =>
          v.playList.id === playlistId
            ? {
                ...v,
                promotions: v.promotions.filter((p) => p.id !== promotionId),
              }
            : v,
        ),
      );
    },
    [],
  );

  const handleSave = () => {
    modifyDevice({
      name: debouncedName,
      model,
      popup,
      devicePlayLists: playlists.map((v) => ({
        ...v,
        promotions: v.promotions.map(({ id, ...rest }) => ({
          ...rest,
          id: id.startsWith(NEW_PROMOTION_ID) ? undefined : id,
        })),
      })),
    });
  };

  useEffect(() => {
    if (data) {
      setName(data.name);
      setModel(data.model);
      setPopup(data.popup);
      setPlaylists(data.devicePlayLists);
      setCheckPromotions(data.devicePlayLists.flatMap((d) => d.promotions));
    }
  }, [data]);

  useEffect(() => {
    if (debouncedName.length > 0 && debouncedName !== data?.name) {
      checkDuplicatedName({ id: deviceId, name: debouncedName });
    } else {
      setNameError({ isError: false, message: '' });
    }
  }, [deviceId, debouncedName]);

  return (
    <>
      {/* header */}
      <Header title="디바이스 수정" moveHandler={leavePageAlertModal.openModal}>
        <Button
          outlined={true}
          size="medium"
          colorType="secondary"
          icon={<IoReloadOutline size={24} />}
          onClick={() => {
            appReload(deviceId);
          }}
        >
          앱 새로고침
        </Button>
        <Button
          outlined={true}
          size="medium"
          colorType="secondary"
          disabled={isArrEmpty(playlists)}
          icon={<IoPlayCircleOutline size={24} />}
          onClick={previewModal.openModal}
        />
        <Button
          size="medium"
          icon={<IoIosAddCircleOutline size={24} />}
          colorType="secondary"
          onClick={playlistModal.openModal}
        >
          재생 목록 추가
        </Button>
        <Button.Loading
          disabled={isSaveDisabled}
          buttonState={buttonState}
          handleSave={handleSave}
        />
      </Header>
      {/* main */}
      <S.Container>
        <S.InfoSection>
          <S.InfoContainer>
            <S.InfoTopContainer>
              <S.InfoTopInput $hasErrorMsg={nameError.isError}>
                <TextField
                  label="이름"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  errorMessage={nameError.message}
                />
                <TextField
                  label="종류"
                  value={model}
                  readOnly
                  disabled={true}
                />
              </S.InfoTopInput>
              <S.InfoTopPopupButton>
                <S.PopupWrapper>
                  <S.PopupLabel>팝업 레이어</S.PopupLabel>
                  <S.PopupName
                    $enable={popup !== null && popup.name.length > 0}
                  >
                    {popup ? popup.name : '팝업 레이어를 추가하세요'}
                  </S.PopupName>
                </S.PopupWrapper>
                {popup === null ? (
                  <Button
                    outlined={true}
                    colorType="color"
                    disabled={false}
                    size="medium"
                    onClick={popupModal.openModal}
                  >
                    추가
                  </Button>
                ) : (
                  <Button
                    outlined={true}
                    colorType={'secondary'}
                    disabled={false}
                    size="medium"
                    onClick={() => setPopup(null)}
                  >
                    삭제
                  </Button>
                )}
              </S.InfoTopPopupButton>
            </S.InfoTopContainer>
            <LimitGuide
              maxPlaylistCount={LIMIT_PLAYLIST}
              maxPromotionCount={LIMIT_PROMOTION}
            />
            <S.PlaylistsContainer>
              {playlists.length > 0 ? (
                <>
                  {playlists
                    .sort((a, b) => (a.isDefault ? -1 : b.isDefault ? 1 : 0))
                    .map((playlist) =>
                      playlist.isDefault ? (
                        <DeviceModiftyPlaylistCard
                          key={playlist.playList.id}
                          playlistId={playlist.playList.id}
                          playContents={playlist.playList.playContents}
                          title={playlist.playList.name}
                          playTime={playlist.playList.totalPlayTime}
                          setDefaultPlaylist={setDefaultPlaylistHandler}
                          deletePlaylist={deletePlaylistHandler}
                        />
                      ) : (
                        <DeviceModiftyPlaylistCard.Promotion
                          key={playlist.playList.id}
                          playlistId={playlist.playList.id}
                          title={playlist.playList.name}
                          playTime={playlist.playList.totalPlayTime}
                          maxPromotionCount={LIMIT_PROMOTION}
                          promotions={playlist.promotions}
                          checkPromotions={checkPromotions}
                          playContents={playlist.playList.playContents}
                          setDefaultPlaylist={setDefaultPlaylistHandler}
                          deletePlaylist={deletePlaylistHandler}
                          addPromotion={addPromotionHandler}
                          editPromotion={editPromotionHandler}
                          deletePromotion={deletePromotionHandler}
                        />
                      ),
                    )}
                </>
              ) : (
                <Empty
                  title=""
                  icon={
                    <FaPhotoVideo size={60} color={PALETTE.component.normal} />
                  }
                  description={
                    '재생 목록을 추가하고 디바이스에서 재생할 콘텐츠를 설정해 보세요.'
                  }
                >
                  <Button
                    type="button"
                    outlined={true}
                    colorType="secondary"
                    onClick={playlistModal.openModal}
                  >
                    재생 목록 추가하기
                  </Button>
                </Empty>
              )}
            </S.PlaylistsContainer>
          </S.InfoContainer>
        </S.InfoSection>
        <PromotionCalendar playlists={playlists} />
      </S.Container>
    </>
  );
};
